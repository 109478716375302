import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Education from './components/pages/Education.js';
import Achievements from './components/pages/Achievements.js';
import Sports from './components/pages/Sport.js';
import Drawing from './components/pages/Drawing.js';
import Cooking from './components/pages/Cooking.js';
import Traveling from './components/pages/Traveling.js';
import Music from './components/pages/Music.js';
import About from './components/pages/About.js';
import MainPage from './components/pages/MainPage.js';
import Extreme from './components/pages/Extreme';

function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/about" element={<About />} />
      <Route path="/music" element={<Music />} />
      <Route path="/traveling" element={<Traveling />} />
      <Route path="/cooking" element={<Cooking />} />
      <Route path="/drawing" element={<Drawing />} />
      <Route path="/sports" element={<Sports />} />
      <Route path="/achievements" element={<Achievements />} />
      <Route path="/education" element={<Education />} />
      <Route path="/extreme" element={<Extreme />} />
      </Routes>
    </Router>
  );
}

export default App;
