import React from 'react';
import './../pages/Sport.css';
import Menu from '../Menu/Menu';

function Sports() {
    return (
      <div className='Sport'>
       <div className='Sport-top-block'>
        <Menu />
        <div className='Sport-top-block-text'>
          <h1>SPORTS</h1>
        </div>
       </div>
       <div className='Sport-bottom-block'>
        <div className='Sport-bottom-block-raw'>
          <div className='Sport-bottom-block-raw-text'>
            <div className='Sport-bottom-block-raw-text-Aikido'><p>Aikido</p>
<p>I learn aikido since I was 
4 years old. Its Japanese
martial arts. Now I have
almost brown belt in it. </p></div>
          <div className='Sport-bottom-block-raw-text-Basketball'><p>Basketball</p>
<p>I love basketball. I'm playing in
IF team. It’s something
new for me, so I am
going to progress in it.</p></div>
          </div>
        </div>
        <div className='Sport-bottom-block-raw'>
          <div className='Sport-bottom-block-raw-image' />
        </div>
       </div>
      </div>
    );
  }
  
  export default Sports;
  