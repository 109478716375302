import React from 'react';
import './../pages/MainPage.css';
import Menu from './../Menu/Menu.js';

function MainPage() {
    return (
      <div className='Main'>
        <div className='Main-block'>
            <Menu />
            <div className='Main-text-left'>
                <p>I am Bohdan</p>
            </div>
        </div>
        <div className='Main-block'>
            <div className='Main-text-right'>
                <p>And I'm 14 </p>
            </div>
        </div>
      </div>
    );
  }
  
  export default MainPage;
  