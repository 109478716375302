import React, { useEffect } from 'react';
import './../pages/Education.css';
import Menu from '../Menu/Menu.js';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';



function Education() {
  return (
    <>
    <div className='Education'>
      <div className='Education-top-block'>
        <Menu />
        <div className='Education-top-block-text'>
          <h1>EDUCATION</h1>
        </div>
      </div>
      <div className='Education-bottom-block'>
        <div className='Education-bottom-block-raw'>
          <div className='Education-bottom-block-raw-text'>
            <div className='Education-bottom-block-raw-text-English'>
              <p> English:</p>
              <p>I started learning English back in kindergarten. Continued with it in school, and after first grade, I added extra English classes. Right now, I'm at a B1 level.</p>
            </div>
          </div>
          <div className='Education-bottom-block-raw-image-2' />
          <div className='Education-bottom-block-raw-text'>
              <div className='Education-bottom-block-raw-text-5-7'>
                <p>5-7 grade:</p>
                <p>I went to school in Kharkiv, at School #62, during grades 5-7. That's where I started learning subjects like geography, biology, chemistry, physics, and German. In 2022, when the war started, my family and I moved from Kharkiv to Ivano-Frankivsk. During 7th grade, I continued my studies online from Ivano-Frankivsk.</p>
              </div>
              
            </div>
            <div className='Education-bottom-block-raw-image-4' />
            <div className='Education-bottom-block-raw-text'>
          <div className='Education-bottom-block-raw-text-8'>
            <p>8th grade:</p>
            <p>Now I go to 8th grade. I changed school on Ivano-Frankivsk Puluy lyceum. To enroll in this lyceum, you need to pass math exams. So I passed them and now I’m learning here.</p>
          </div>
        </div>
        <div className='Education-bottom-block-raw-image-6' />
        </div>
        
        <div className='Education-bottom-block-raw'>
          <div className='Education-bottom-block-raw-image' />
          <div className='Education-bottom-block-raw-text'>
            <div className='Education-bottom-block-raw-text-1-4'>
              <p>1-4 grade:</p>
              <p>I went to Kharkiv School #99 for grades 1-4. There, I learned the basics of different subjects. From 1st grade, I joined logical and physics extra classes. In 3rd grade, I also signed up for extra chess classes, thinking they helped me with strategy.</p>
            </div>
          </div>
          <div className='Education-bottom-block-raw-image-3' />
          <div className='Education-bottom-block-raw-text'>
          <div className='Education-bottom-block-raw-text-polish'>
            <p>Polish:</p>
            <p>In Ivano-Frankivsk, I went on extra Polish classes. I studied Polish for 1 year. I can't say that I know Polish well, but I can understand it and simply speak in it.</p>
          </div>
          </div>
          <div className='Education-bottom-block-raw-image-5' />
          <div className='Education-bottom-block-raw-text'>
            <div className='Education-bottom-block-raw-text-program'>
              <p>Programming:</p>
              <p>From 1st to 4th grade, I took simple programming classes using Scratch, which served as a good introduction to the IT field. During summer vacations between 7th and 8th grade, I attended a Java programming course. I also tried learning Python, but it didn't come easy for me. On my own, I picked up JavaScript and used it to create this website. </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ScrollIndicator />
    </>
  );
}

export default Education;
