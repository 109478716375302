import React from 'react';
import './../pages/Cooking.css';
import Menu from '../Menu/Menu';

function Cooking() {
    return (
      <div className='Cooking'>
       <div className='Cooking-top-block'>
        <Menu />
        <div className='Cooking-top-block-text'>
          <h1>COOKING</h1>
        </div>
       </div>
       <div className='Cooking-bottom-block'>
        <div className='Cooking-bottom-block-raw'>
          <div className='Cooking-bottom-block-raw-text'>
            <p>I really enjoy cooking, especially for my parents. I've made quite a few tasty dishes, but my top 5 favorites are: 1. Lasagna 2. Donuts 3. Chicken wings 4. Khinkali 5. Tacos. My mom usually helps me out, and as I get older, I hope to cook even better and more impressive meals, like a professional chef.</p>
          </div>
        </div>
        <div className='Cooking-bottom-block-raw'>
          <div className='Cooking-bottom-block-raw-image' />
        </div>
       </div>
      </div>
    );
  }
  
  export default Cooking;
  