import React from 'react';
import './../pages/Drawing.css';
import Menu from '../Menu/Menu';

function Drawing() {
    return (
      <div className='Drawing'>
       <div className='Drawing-top-block'>
        <Menu />
        <div className='Drawing-top-block-text'>
          <h1>DRAWING</h1>
        </div>
       </div>
       <div className='Drawing-bottom-block'>
        <div className='Drawing-bottom-block-raw'>
          <div className='Drawing-bottom-block-raw-text'>
            <p>I never really learned how to draw, but my dad showed me how to doodle funny and silly people, and it became my own unique style. Whenever I have free time, I enjoy drawing these quirky characters. Lately, I've also gotten into graffiti, and I like writing words on stickers.</p>
          </div>
        </div>
        <div className='Drawing-bottom-block-raw'>
          <div className='Drawing-bottom-block-raw-image' />
        </div>
       </div>
      </div>
    );
  }
  
  export default Drawing;
  