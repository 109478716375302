import React from 'react';
import './../pages/Achievements.css';
import Menu from '../Menu/Menu';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';

function Achievements() {
    return (
      <>
      <div className='Achievements'>
       <div className='Achievements-top-block'>
        <Menu />
        <div className='Achievements-top-block-text'>
          <h1>ACHIEVEMENTS</h1>
        </div>
       </div>
       <div className='Achievements-bottom-block'>
        <div className='Achievements-bottom-block-raw'>
          <div className='Achievements-bottom-block-raw-text'>
            <div className='Achievements-bottom-block-raw-text-Aikido'><p> Aikido:</p>
  <p>10th adult belt
  1st place on kendo competition
  1st place on exercises competition 
  2nd place aikido competition, 2019
  2nd place aikido competition, 2021
  3rd place aikido competition, 2018</p>
          </div>
          </div>
          <div className='Achievements-bottom-block-raw-image-2'/>
        </div>
        <div className='Achievements-bottom-block-raw'>
          <div className='Achievements-bottom-block-raw-image' />
          <div className='Achievements-bottom-block-raw-text'>
            <div className='Achievements-bottom-block-raw-text-basketball'><p>Programming:</p>
  <p>Finished IT course in Kharkiv 2017
  finished IT course in Kharkiv 2018
  finished IT course in Kharkiv 2019
  finished IT course in Kharkiv 2020
  finished 1st and 2nd Java course</p>
            </div>
          </div>
        </div>
       </div>
      </div>
      <ScrollIndicator />
      </>
    );
  }
  
  export default Achievements;
  