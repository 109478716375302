import React from 'react';
import './../pages/Extreme.css';
import Menu from '../Menu/Menu';
import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';

function Extreme() {
    return (
      <>
      <div className='Extreme'>
       <div className='Extreme-top-block'>
        <Menu />
        <div className='Extreme-top-block-text'>
          <h1>EXTREME</h1>
        </div>
       </div>
       <div className='Extreme-bottom-block'>
        <div className='Extreme-bottom-block-raw'>
          <div className='Extreme-bottom-block-raw-text'>
            <div className='Extreme-bottom-block-raw-text-skateboarding'><p> Skateboarding:</p>
  <p>I started skateboarding when I was 12, and I've been at it for three months now. In that time, I've learned how to do tricks like ollie, pop-shove, front-shove, and 180 grab..</p>
          </div>
          </div>
          <div className='Extreme-bottom-block-raw-image-2'/>
        </div>
        <div className='Extreme-bottom-block-raw'>
          <div className='Extreme-bottom-block-raw-image' />
          <div className='Extreme-bottom-block-raw-text'>
            <div className='Extreme-bottom-block-raw-text-scootering'><p>Scootering:</p>
  <p> I got bored with skateboarding and picked up scootering, which has become my main hobby for over a year now. I've been progressing and practicing tricks like inward bri, bri whip, and umbrella whip. I'm determined to keep improving and never give up on it.</p>
            </div>
          </div>
        </div>
       </div>
      </div>
      <ScrollIndicator />
      </>
    );
    
  }
  
  export default Extreme;
  