import React from 'react';
import './../pages/Traveling.css';
import Menu from '../Menu/Menu';

function Traveling() {
    return (
      <div className='Traveling'>
       <div className='Traveling-top-block'>
        <Menu />
        <div className='Traveling-top-block-text'>
          <h1>TRAVELING</h1>
        </div>
       </div>
       <div className='Traveling-bottom-block'>
        <div className='Traveling-bottom-block-raw'>
          <div className='Traveling-bottom-block-raw-text'>
            <p>I really enjoy traveling and have been to the USA, France, Spain, Greece, Poland, Egypt, Tunisia, Turkey, Montenegro, and Romania. It's fascinating to discover new things like languages, traditions, and meeting different people. Plus, it's a great way to unwind and relax, something I absolutely love.</p>
          </div>
        </div>
        <div className='Traveling-bottom-block-raw'>
          <div className='Traveling-bottom-block-raw-image' />
        </div>
       </div>
      </div>
    );
  }
  
  export default Traveling;
  