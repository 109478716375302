import React, { useState, useEffect, useRef } from "react";
import  './Menu.css';
import menu_white from './..//../images/menu_white.svg';
import menu_grey from './..//../images/menu_grey.svg';
import { useNavigate, useLocation } from "react-router-dom";

const items = [
    {
        name: "Main page",
        url: "/"
    },
    {
      name: "About me",
      url: "/about",
    },
    {
      name: "Interests",
      url: "/interests",
      subitems: [
        {
          name: "Extreme",
          url: "/extreme",
        },
        {
          name: "Drawing",
          url: "/drawing",
        },
        {
          name: "Traveling",
          url: "/traveling",
        },
        {
          name: "Music",
          url: "/music",
        },
        {
          name: "Cooking",
          url: "/cooking",
        },
        {
          name: "Sport",
          url: "/sports",
        },
      ],
    },
    {
      name: "Education",
      url: "/education",
    },
    {
      name: "Achievements",
      url: "/achievements",
    },
  ];

const Menu = () => {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const dropdownRef = useRef(null);
    
    useEffect(() => {
        const handleDocumentClick = (event) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setMenuOpen(false);
          } 
        }
        if (isMenuOpen) {
          document.addEventListener('click', handleDocumentClick)
        }
        return () => {
          document.removeEventListener('click', handleDocumentClick)
        }
    })

    const toggleMenu = () => {
        setMenuOpen(!isMenuOpen);
    }

    const toggleSubMenu = () => {
        setSubMenuOpen(!isSubMenuOpen);
    }

    const handleMenuItemClick = (item) => {
        setSubMenuOpen(false);
        setMenuOpen(false);
        navigate(item.url);
    }

    return (
        <div className={`Menu-container ${isMenuOpen ? "open" : ""}`} ref={dropdownRef}>
           <div className="Menu-icon" onClick={toggleMenu}>
            <img src={isMenuOpen ? menu_grey : menu_white} alt=""/>
           </div> 

           {isMenuOpen && (
            <ul className="Menu-list">
                {items.map((item) => 
                    item?.subitems ? (
                        <li className="Submenu" key={item.name} onMouseEnter={toggleSubMenu} onMouseLeave={toggleSubMenu}>
                            {item.name}
                            <span>&#x2971;</span>
                            {isSubMenuOpen && (
                                <ul className="Submenu-list">
                                    {item.subitems.map((subitem) => (
                                        <li className={`Menu-item ${subitem.url === location.pathname ? "checked" : ""}`} key={subitem.name} onClick={() => handleMenuItemClick(subitem)}>
                                            {subitem.name}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ) : (
                        <li  className={`Menu-item ${item.url === location.pathname ? "checked" : ""}`} key={item.name} onClick={() => handleMenuItemClick(item)}>
                            {item.name}
                        </li>  
                    )
                )}
            </ul>
           )}
        </div>
    )
};

export default Menu;