import React, { useState, useEffect } from "react";
import './../../components/ScrollIndicator/ScrollIndicator.css';

/* const ScrollIndicator = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY // => scroll position
            console.log(scrollPosition)
        }
        
        window.addEventListener("scroll", handleScroll)
        
        return () => {
            window.removeEventListener("scroll", handleScroll)
        }
    }, [])
        
    return (
        <div style={{ height: "500px" }}>
            <h4>Hello</h4>
        </div>
        // <div className={`Scroll-indicator ${''}`}>
        //     &#x21e9;
        // </div>
    );
  }; */

const ScrollIndicator = () => {
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            const scrollHeight = document.documentElement.scrollHeigth || document.body.scrollHeight;
            const clientHeight = document.documentElement.clientHeight || window.innerHeight;
            const isBottom = scrollTop + clientHeight >= scrollHeight - 1;
            setIsScrolledToBottom(isBottom)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
          }
    }, [])

    return (
        <div className={`Scroll-indicator ${isScrolledToBottom ? 'hide' : ''}`}>
            &#x21e9;
        </div>
    )
}


export default ScrollIndicator