import React from 'react';
import './../pages/About.css';
import Menu from '../Menu/Menu.js';

function About() {
    return (
      <div className='About'>
       <div className='About-top-block'>
        <Menu />
        <div className='About-top-block-text'>
          <h1>ABOUT ME</h1>
        </div>
       </div>
       <div className='About-bottom-block'>
        <div className='About-bottom-block-raw'>
          <div className='About-bottom-block-raw-text'>
            <p>Hello, I'm Bohdan Zlatkin, and I'm 14 years old, currently in 8th grade. I have several hobbies, and you can find more details about them on this website. My big goal for the future is to become a programmer, and this project marks my first significant step toward that dream.</p>
          </div>
        </div>
        <div className='About-bottom-block-raw'>
          <div className='About-bottom-block-raw-image' />
        </div>
       </div>
      </div>
    );
  }
  
  export default About;
  