import React from 'react';
import './../pages/Music.css';
import Menu from '../Menu/Menu';

function Music() {
    return (
      <div className='Music'>
       <div className='Music-top-block'>
        <Menu />
        <div className='Music-top-block-text'>
          <h1>MUSIC</h1>
        </div>
       </div>
       <div className='Music-bottom-block'>
        <div className='Music-bottom-block-raw'>
          <div className='Music-bottom-block-raw-text'>
            <p>I really enjoy listening to music when I'm on a trip. For instance, when we go to Olexandria to visit my grandparents, I can have my music playing for up to 7 hours. I like a mix of trash, rock, and trap music. I listen to rock and trash with my dad, and when it comes to trash music, I share that with my friends.</p>
          </div>
        </div>
        <div className='Music-bottom-block-raw'>
          <div className='Music-bottom-block-raw-image' />
        </div>
       </div>
      </div>
    );
  }
  
  export default Music;
  